import tvsdLogo from 'assets/images/tvsd_logo.png';
import aristaLogo from 'assets/images/arista_logo.png';
import mazdaLogo from 'assets/images/mazda_logo.svg';
import wheeltekLogo from 'assets/images/wheeltek_logo.png';
// import motoraceLogo from 'assets/images/motorace_logo.png';

import aristaBackground from 'assets/images/arista_background.png';
import mazdaBackground from 'assets/images/mazda_background.png';
import wheeltekBackground from 'assets/images/wheeltek_background.png';

import { DEPLOYMENT_ENV, ASSETS_BASE_URL } from 'config';

export enum UserRolesEnum {
	Admin = 'admin',
	Corporate = 'corporate-director',
	Brand = 'brand-director',
	Operation = 'ops-manager',
	Branch = 'branch-manager',
	Supervisor = 'supervisor',
	TeamSupervisor = 'team-supervisor',
}

export enum ClientEnum {
	ARISTA = 'arista',
	MAZDA = 'mazda',
	WHEELTEK = 'wheeltek',
	MOTORACE = 'motorace',
	NEWNEMAR = 'newnemar',
	TVSD = 'tvsd',
}

export const INTEGRATED_WITH_UP =
	DEPLOYMENT_ENV === ClientEnum.WHEELTEK ||
	DEPLOYMENT_ENV === ClientEnum.MOTORACE ||
	DEPLOYMENT_ENV === ClientEnum.NEWNEMAR ||
	DEPLOYMENT_ENV === ClientEnum.TVSD;

export const OUR_LOGO = tvsdLogo;
export const POWERED_BY_OUR_LOGO = `${ASSETS_BASE_URL}/ui/powered_by_tvsd.png`;
export const POWERED_BY_OUR_LOGO_GREY = `${ASSETS_BASE_URL}/ui/powered_by_tvsd_grey.png`;
export const APEC_LOGO = `${ASSETS_BASE_URL}/ui/apec_logo_color.png`;
export const APEC_LOGO_WHITE = `${ASSETS_BASE_URL}/ui/apec_logo_white.png`;

export const CLIENT_LOGO =
	DEPLOYMENT_ENV === ClientEnum.ARISTA
		? aristaLogo
		: DEPLOYMENT_ENV === ClientEnum.MAZDA
			? mazdaLogo
			: `${ASSETS_BASE_URL}/ui/logo.png`;

export const CLIENT_BACKGROUND =
	DEPLOYMENT_ENV === ClientEnum.ARISTA
		? aristaBackground
		: DEPLOYMENT_ENV === ClientEnum.MAZDA
			? mazdaBackground
			: `${ASSETS_BASE_URL}/ui/background.png`;

export const CLIENT_LOCALE =
	DEPLOYMENT_ENV === ClientEnum.ARISTA || DEPLOYMENT_ENV === ClientEnum.MAZDA
		? 'id'
		: 'en';

export const PROFILE_PHOTO = `${ASSETS_BASE_URL}/ui/profile_placeholder.png`;

// 7200 seconds = 2 hours
export const DEFAULT_INACTIVITY_MILISECONDS = 7200 * 1000;
